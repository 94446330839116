import { MAILTO_EMAIL } from "../../constants";
import "./form.css";
import fieldsArr from "./prePlanFields";
import schema from "./prePlanSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import React from "react";
import { useForm } from "react-hook-form";

const PrePlanForm = () => {
  const defaultValues = {
    sex: undefined,
    "birth-state": undefined,
    "marital-status": undefined,
    "residence-state": undefined,
    "city-limits": undefined,
    "armed-forces": undefined,
    "armed-forces-discharge-papers": undefined,
    education: undefined,
    race: undefined,
    "contact-state": undefined,
    hospice: undefined,
    pacemaker: undefined,
    "death-certificates-number": 0,
  };

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const deathCertificatesNumber = watch("death-certificates-number", 0);

  const formURL =
    "https://3dodu20k1m.execute-api.us-east-1.amazonaws.com/Prod/send";
  const onSubmit = (submission) => {
    const now = dayjs();
    const dateFormat = (date) => dayjs(date).format("MM/DD/YYYY");
    const ssnFormat = (ssn) => {
      const ssnArr = Array.from(ssn.split("-").join(""));
      ssnArr.splice(5, 0, "-");
      ssnArr.splice(3, 0, "-");
      return ssnArr.join("");
    };

    submission["date-of-birth"] = dateFormat(submission["date-of-birth"]);
    submission.ssn = ssnFormat(submission.ssn);
    submission["death-certificates-number"] =
      3 + +submission["death-certificates-number"];

    const dehyphenate = (text) => {
      return text
        .replace(/-/g, " ")
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };

    const stringFormatter = (fieldsArr, responsesArr) => {
      let retval = "";
      for (var i = 0; i < fieldsArr.length; i++) {
        retval += dehyphenate(fieldsArr[i]) + ": " + responsesArr[i] + "\n\n";
      }
      return retval;
    };

    const toEmails = [MAILTO_EMAIL];
    // const toEmails = ['info@cityofoakscremation.com'];
    const ccEmails = ["mailer@lyda.me"];

    const data = Object.assign(
      {
        "submission-type": "Pre-Planner",
        "time-submitted": now.format("MM/DD/YYYY HH:mm"),
      },
      submission
    );
    const responsesArr = [];
    fieldsArr.map((field) => responsesArr.push(data[field]));

    const subject = `Pre-plan Info for ${submission["legal-name-first"]} ${submission["legal-name-last"]}`;
    const message = stringFormatter(fieldsArr, responsesArr);
    const { "contact-email": informantEmail } = submission;

    const mailObj = {
      toEmails,
      ccEmails,
      subject,
      message,
      obitInfo: null,
      informantEmail: informantEmail.trim(),
    };

    // Create the AJAX request
    const xhr = new XMLHttpRequest();
    xhr.open("POST", formURL, true);
    xhr.setRequestHeader("Accept", "application/json; charset=utf-8");
    xhr.setRequestHeader("Content-Type", "application/json; charset=UTF-8");

    // Send the collected data as stringified JSON
    console.log(mailObj);
    xhr.send(JSON.stringify(mailObj));

    xhr.onloadend = (response) => {
      if (response.target.status === 200) {
        window.location = "https://www.cityofoakscremation.com/success/";
        console.log(response, mailObj);
      } else {
        alert(
          "Oops! Something isn't quite right. Please check all fields and try again."
        );
        console.error(response);
      }
      console.log(errors);
    };
  };

  return (
    <>
      <form className="form-input" onSubmit={handleSubmit(onSubmit)}>
        <div className="rendered-form">
          <div className=" form-group field-legal-name">
            <br />
            <h3>Individual's full legal name:</h3>
            <div className="block">
              <label for="legal-name-first" className="text-gray-700 ">
                First:
                <span className="required-asterisk">
                  <span className="asterisk-text">required</span>*
                </span>
              </label>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("legal-name-first")}
                  access="false"
                  id="legal-name-first"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["legal-name-first"] && (
                <div className="error-msg">
                  {errors["legal-name-first"].message}
                </div>
              )}
              <div className="block">
                <label
                  for="legal-name-middle"
                  className="text-gray-700 text-gray-700 "
                >
                  Middle:
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("legal-name-middle")}
                  access="false"
                  id="legal-name-middle"
                />
              </div>
              {errors["legal-name-middle"] && (
                <div className="error-msg">
                  {errors["legal-name-middle"].message}
                </div>
              )}
              <div className="block">
                <label
                  for="legal-name-last"
                  className="text-gray-700 text-gray-700 "
                >
                  Last:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("legal-name-last")}
                  access="false"
                  id="legal-name-last"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["legal-name-last"] && (
                <div className="error-msg">
                  {errors["legal-name-last"].message}
                </div>
              )}
              <div className=" form-group field-maiden-name"></div>
              <div className="block">
                <label
                  for="maiden-name"
                  className="text-gray-700 text-gray-700 "
                >
                  Last name prior to first marriage:
                  <span className="tooltip">
                    {" "}
                    <span className="tooltip-text">(if applicable)</span>?
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("maiden-name")}
                  access="false"
                  id="maiden-name"
                  title="(if applicable)"
                />
              </div>
              {errors["maiden-name"] && (
                <div className="error-msg">{errors["maiden-name"].message}</div>
              )}
              <div className=" form-group field-sex"></div>
              <div className="block">
                <label for="sex" className="text-gray-700 text-gray-700 ">
                  Sex:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                  <span className="tooltip">
                    <span className="tooltip-text">
                      (according to official records)
                    </span>
                  </span>
                  <br />
                </label>
                <select
                  className="form-select sex"
                  {...register("sex")}
                  access="false"
                  id="sex"
                  required="required"
                  aria-required="true"
                >
                  <option value={undefined} disabled selected="true">
                    Select sex:
                  </option>
                  <option value="male" id="sex-0">
                    Male
                  </option>
                  <option value="female" id="sex-1">
                    Female
                  </option>
                </select>
              </div>
              {errors.sex && (
                <div className="error-msg">{errors.sex.message}</div>
              )}
              <div className=" form-group field-age"></div>
              <div className="block">
                <label
                  for="age"
                  className="text-gray-700 text-gray-700 text-gray-700 "
                >
                  Age at last birthday:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("age")}
                  access="false"
                  maxlength="3"
                  id="age"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["age"] && (
                <div className="error-msg">{errors["age"].message}</div>
              )}
              <br />
              <h3>County and state of birth:</h3>
              <div className=" form-group field-county"></div>
              <div className="block">
                <label for="county" className="text-gray-700 ">
                  County:
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input county"
                  {...register("county")}
                  access="false"
                  // value=''
                  id="county"
                />
              </div>
              {errors["county"] && (
                <div className="error-msg">{errors["county"].message}</div>
              )}
              <div className=" form-group field-birth-state"></div>
              <div className="block">
                <label
                  for="birth-state"
                  className="text-gray-700 text-gray-700 "
                >
                  State: <br />{" "}
                </label>
                <select
                  className="form-select individual-form"
                  {...register("birth-state")}
                  access="false"
                  id="birth-state"
                >
                  {" "}
                  <option value={undefined} disabled selected="true">
                    Please select:
                  </option>
                  <option value="Alabama" id="birth-state-0">
                    Alabama
                  </option>
                  <option value="Alaska" id="birth-state-1">
                    Alaska
                  </option>
                  <option value="Arizona" id="birth-state-2">
                    Arizona
                  </option>
                  <option value="Arkansas" id="birth-state-3">
                    Arkansas
                  </option>
                  <option value="California" id="birth-state-4">
                    California
                  </option>
                  <option value="Colorado" id="birth-state-5">
                    Colorado
                  </option>
                  <option value="Connecticut" id="birth-state-6">
                    Connecticut
                  </option>
                  <option value="Delaware" id="birth-state-7">
                    Delaware
                  </option>
                  <option value="Florida" id="birth-state-8">
                    Florida
                  </option>
                  <option value="Georgia" id="birth-state-9">
                    Georgia
                  </option>
                  <option value="Hawaii" id="birth-state-10">
                    Hawaii
                  </option>
                  <option value="Idaho" id="birth-state-11">
                    Idaho
                  </option>
                  <option value="Illinois" id="birth-state-12">
                    Illinois
                  </option>
                  <option value="Indiana" id="birth-state-13">
                    Indiana
                  </option>
                  <option value="Iowa" id="birth-state-14">
                    Iowa
                  </option>
                  <option value="Kansas" id="birth-state-15">
                    Kansas
                  </option>
                  <option value="Kentucky" id="birth-state-16">
                    Kentucky
                  </option>
                  <option value="Louisiana" id="birth-state-17">
                    Louisiana
                  </option>
                  <option value="Maine" id="birth-state-18">
                    Maine
                  </option>
                  <option value="Maryland" id="birth-state-19">
                    Maryland
                  </option>
                  <option value="Massachusetts" id="birth-state-20">
                    Massachusetts
                  </option>
                  <option value="Michigan" id="birth-state-21">
                    Michigan
                  </option>
                  <option value="Minnesota" id="birth-state-22">
                    Minnesota
                  </option>
                  <option value="Mississippi" id="birth-state-23">
                    Mississippi
                  </option>
                  <option value="Missouri" id="birth-state-24">
                    Missouri
                  </option>
                  <option value="Montana" id="birth-state-25">
                    Montana
                  </option>
                  <option value="Nebraska" id="birth-state-26">
                    Nebraska
                  </option>
                  <option value="Nevada" id="birth-state-27">
                    Nevada
                  </option>
                  <option value="New-Hampshire" id="birth-state-28">
                    New Hampshire
                  </option>
                  <option value="New-Jersey" id="birth-state-29">
                    New Jersey
                  </option>
                  <option value="New-Mexico" id="birth-state-30">
                    New Mexico
                  </option>
                  <option value="New-York" id="birth-state-31">
                    New York
                  </option>
                  <option value="North-Carolina" id="birth-state-32">
                    North Carolina
                  </option>
                  <option value="North-Dakota" id="birth-state-33">
                    North Dakota
                  </option>
                  <option value="Ohio" id="birth-state-34">
                    Ohio
                  </option>
                  <option value="Oklahoma" id="birth-state-35">
                    Oklahoma
                  </option>
                  <option value="Oregon" id="birth-state-36">
                    Oregon
                  </option>
                  <option value="Pennsylvania" id="birth-state-37">
                    Pennsylvania
                  </option>
                  <option value="Rhode-Island" id="birth-state-38">
                    Rhode Island
                  </option>
                  <option value="South-Carolina" id="birth-state-39">
                    South Carolina
                  </option>
                  <option value="South-Dakota" id="birth-state-40">
                    South Dakota
                  </option>
                  <option value="Tennessee" id="birth-state-41">
                    Tennessee
                  </option>
                  <option value="Texas" id="birth-state-42">
                    Texas
                  </option>
                  <option value="Utah" id="birth-state-43">
                    Utah
                  </option>
                  <option value="Vermont" id="birth-state-44">
                    Vermont
                  </option>
                  <option value="Virginia" id="birth-state-45">
                    Virginia
                  </option>
                  <option value="Washington" id="birth-state-46">
                    Washington
                  </option>
                  <option value="West-Virginia" id="birth-state-47">
                    West Virginia
                  </option>
                  <option value="Wisconsin" id="birth-state-48">
                    Wisconsin
                  </option>
                  <option value="Wyoming" id="birth-state-49">
                    Wyoming
                  </option>
                  <option value="Washington-DC" id="birth-state-50">
                    Washington, D.C.
                  </option>
                  <option value="Other" id="birth-state-51">
                    Other/Foreign Country (please specify)
                  </option>
                </select>
              </div>
              {errors["birth-state"] && (
                <div className="error-msg">{errors["birth-state"].message}</div>
              )}
              <div className=" form-group field-birth-country"></div>
              <div className="block">
                <label
                  for="birth-country"
                  className="text-gray-700 text-gray-700 "
                >
                  If other or foreign country, please specify:{" "}
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input decedent-form"
                  {...register("birth-country")}
                  access="false"
                  id="birth-country"
                />
              </div>
              {errors["birth-country"] && (
                <div className="error-msg">
                  {errors["birth-country"].message}
                </div>
              )}
              <div className=" form-group field-date-of-birth"></div>
              <div className="block">
                <label
                  for="date-of-birth"
                  className="text-gray-700 text-gray-700 "
                >
                  Date of birth:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  placeholder="01/01/1921"
                  className="form-input individual-form"
                  {...register("date-of-birth")}
                  access="false"
                  id="date-of-birth"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["date-of-birth"] && (
                <div className="error-msg">
                  {errors["date-of-birth"].message}
                </div>
              )}
              <div className=" form-group field-marital-status"></div>
              <div className="block">
                <label
                  for="marital-status"
                  className="text-gray-700 text-gray-700 "
                >
                  Marital Status
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                  <br />
                </label>
                <select
                  className="form-select individual-form"
                  {...register("marital-status")}
                  id="marital-status"
                  aria-required="true"
                >
                  <option value={undefined} disabled selected="true">
                    Please select:
                  </option>
                  <option value="Married" id="marital-status-0">
                    Married
                  </option>
                  <option value="Separated" id="marital-status-1">
                    Separated
                  </option>
                  <option value="Divorced" id="marital-status-2">
                    Divorced
                  </option>
                  <option value="Never married" id="marital-status-3">
                    Never married
                  </option>
                  <option value="Widowed" id="marital-status-4">
                    Widowed
                  </option>
                  <option value="Unknown" id="marital-status-5">
                    Unknown
                  </option>
                </select>
              </div>
              {errors["marital-status"] && (
                <div className="error-msg">
                  {errors["marital-status"].message}
                </div>
              )}
              <div className=" form-group field-surviving-spouse"></div>
              <div className="block">
                <label
                  for="surviving-spouse"
                  className="text-gray-700 text-gray-700 "
                >
                  Spouse's name (if wife, use maiden name):
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("surviving-spouse")}
                  access="false"
                  id="surviving-spouse"
                />
              </div>
              {errors["surviving-spouse"] && (
                <div className="error-msg">
                  {errors["surviving-spouse"].message}
                </div>
              )}
              <div className=" form-group field-business-occupation"></div>
              <div className="block">
                <label
                  for="business-occupation"
                  className="text-gray-700 text-gray-700 "
                >
                  Individual's usual occupation (do not use retired or
                  disabled):
                  <span className="tooltip">
                    <span className="tooltip-text">
                      If retired or disabled, please list last occupation before
                      retirement or disability.
                    </span>
                    ?
                  </span>
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("business-occupation")}
                  access="false"
                  id="business-occupation"
                  title="(do not use retired)"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["business-occupation"] && (
                <div className="error-msg">
                  {errors["business-occupation"].message}
                </div>
              )}
              <div className=" form-group field-business-industry"></div>
              <div className="block">
                <label
                  for="business-industry"
                  className="text-gray-700 text-gray-700 "
                >
                  Kind of business/industry:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("business-industry")}
                  access="false"
                  id="business-industry"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["business-industry"] && (
                <div className="error-msg">
                  {errors["business-industry"].message}
                </div>
              )}
              <div className=" form-group field-ssn"></div>
              <div className="block">
                <label for="ssn" className="text-gray-700">
                  Social Security Number
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  placeholder="000-00-0000"
                  className="form-input individual-form"
                  {...register("ssn")}
                  access="false"
                  id="ssn"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["ssn"] && (
                <div className="error-msg">{errors["ssn"].message}</div>
              )}
              <br />
              <h3>Individual's residence:</h3>
              <div className=" form-group field-residence-state"></div>
              <div className="block">
                <label
                  for="residence-state"
                  className="text-gray-700 text-gray-700 "
                >
                  Residence-State or Foreign Country:
                  <br />{" "}
                </label>
                <select
                  className="form-select individual-form"
                  {...register("residence-state")}
                  access="false"
                  id="residence-state"
                >
                  <option value={undefined} disabled selected="true">
                    Please select:
                  </option>
                  <option value="Alabama" id="residence-state-0">
                    Alabama
                  </option>
                  <option value="Alaska" id="residence-state-1">
                    Alaska
                  </option>
                  <option value="Arizona" id="residence-state-2">
                    Arizona
                  </option>
                  <option value="Arkansas" id="residence-state-3">
                    Arkansas
                  </option>
                  <option value="California" id="residence-state-4">
                    California
                  </option>
                  <option value="Colorado" id="residence-state-5">
                    Colorado
                  </option>
                  <option value="Connecticut" id="residence-state-6">
                    Connecticut
                  </option>
                  <option value="Delaware" id="residence-state-7">
                    Delaware
                  </option>
                  <option value="Florida" id="residence-state-8">
                    Florida
                  </option>
                  <option value="Georgia" id="residence-state-9">
                    Georgia
                  </option>
                  <option value="Hawaii" id="residence-state-10">
                    Hawaii
                  </option>
                  <option value="Idaho" id="residence-state-11">
                    Idaho
                  </option>
                  <option value="Illinois" id="residence-state-12">
                    Illinois
                  </option>
                  <option value="Indiana" id="residence-state-13">
                    Indiana
                  </option>
                  <option value="Iowa" id="residence-state-14">
                    Iowa
                  </option>
                  <option value="Kansas" id="residence-state-15">
                    Kansas
                  </option>
                  <option value="Kentucky" id="residence-state-16">
                    Kentucky
                  </option>
                  <option value="Louisiana" id="residence-state-17">
                    Louisiana
                  </option>
                  <option value="Maine" id="residence-state-18">
                    Maine
                  </option>
                  <option value="Maryland" id="residence-state-19">
                    Maryland
                  </option>
                  <option value="Massachusetts" id="residence-state-20">
                    Massachusetts
                  </option>
                  <option value="Michigan" id="residence-state-21">
                    Michigan
                  </option>
                  <option value="Minnesota" id="residence-state-22">
                    Minnesota
                  </option>
                  <option value="Mississippi" id="residence-state-23">
                    Mississippi
                  </option>
                  <option value="Missouri" id="residence-state-24">
                    Missouri
                  </option>
                  <option value="Montana" id="residence-state-25">
                    Montana
                  </option>
                  <option value="Nebraska" id="residence-state-26">
                    Nebraska
                  </option>
                  <option value="Nevada" id="residence-state-27">
                    Nevada
                  </option>
                  <option value="New-Hampshire" id="residence-state-28">
                    New Hampshire
                  </option>
                  <option value="New-Jersey" id="residence-state-29">
                    New Jersey
                  </option>
                  <option value="New-Mexico" id="residence-state-30">
                    New Mexico
                  </option>
                  <option value="New-York" id="residence-state-31">
                    New York
                  </option>
                  <option value="North-Carolina" id="residence-state-32">
                    North Carolina
                  </option>
                  <option value="North-Dakota" id="residence-state-33">
                    North Dakota
                  </option>
                  <option value="Ohio" id="residence-state-34">
                    Ohio
                  </option>
                  <option value="Oklahoma" id="residence-state-35">
                    Oklahoma
                  </option>
                  <option value="Oregon" id="residence-state-36">
                    Oregon
                  </option>
                  <option value="Pennsylvania" id="residence-state-37">
                    Pennsylvania
                  </option>
                  <option value="Rhode-Island" id="residence-state-38">
                    Rhode Island
                  </option>
                  <option value="South-Carolina" id="residence-state-39">
                    South Carolina
                  </option>
                  <option value="South-Dakota" id="residence-state-40">
                    South Dakota
                  </option>
                  <option value="Tennessee" id="residence-state-41">
                    Tennessee
                  </option>
                  <option value="Texas" id="residence-state-42">
                    Texas
                  </option>
                  <option value="Utah" id="residence-state-43">
                    Utah
                  </option>
                  <option value="Vermont" id="residence-state-44">
                    Vermont
                  </option>
                  <option value="Virginia" id="residence-state-45">
                    Virginia
                  </option>
                  <option value="Washington" id="residence-state-46">
                    Washington
                  </option>
                  <option value="West-Virginia" id="residence-state-47">
                    West Virginia
                  </option>
                  <option value="Wisconsin" id="residence-state-48">
                    Wisconsin
                  </option>
                  <option value="Wyoming" id="residence-state-49">
                    Wyoming
                  </option>
                  <option value="Washington-DC" id="residence-state-50">
                    Washington, D.C.
                  </option>
                  <option value="Other" id="residence-state-51">
                    Other/Foreign Country (please specify)
                  </option>
                </select>
              </div>
              {errors["residence-state"] && (
                <div className="error-msg">
                  {errors["residence-state"].message}
                </div>
              )}
              <div className=" form-group field-residence-country"></div>
              <div className="block">
                <label
                  for="residence-country"
                  className="text-gray-700 text-gray-700 "
                >
                  If foreign country, please specify:{" "}
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("residence-country")}
                  access="false"
                  id="residence-country"
                />
              </div>
              {errors["residence-country"] && (
                <div className="error-msg">
                  {errors["residence-country"].message}
                </div>
              )}
              <div className=" form-group field-residence-county"></div>
              <div className="block">
                <label
                  for="residence-county"
                  className="text-gray-700 text-gray-700 "
                >
                  County:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("residence-county")}
                  access="false"
                  id="residence-county"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["residence-county"] && (
                <div className="error-msg">
                  {errors["residence-county"].message}
                </div>
              )}
              <div className=" form-group field-residence-address"></div>
              <div className="block">
                <label
                  for="residence-address"
                  className="text-gray-700 text-gray-700 "
                >
                  Street and number:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("residence-address")}
                  access="false"
                  id="residence-address"
                ></input>
              </div>
              {errors["residence-address"] && (
                <div className="error-msg">
                  {errors["residence-address"].message}
                </div>
              )}
              <div className=" form-group field-residence-city"></div>
              <div className="block">
                <label
                  for="residence-city"
                  className="text-gray-700 text-gray-700 "
                >
                  City:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("residence-city")}
                  access="false"
                  id="residence-city"
                ></input>
              </div>
              {errors["residence-city"] && (
                <div className="error-msg">
                  {errors["residence-city"].message}
                </div>
              )}
              <div className=" form-group field-residence-zip"></div>
              <div className="block">
                <label
                  for="residence-zip"
                  className="text-gray-700 text-gray-700 "
                >
                  Zip Code:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("residence-zip")}
                  access="false"
                  id="residence-zip"
                ></input>
              </div>
              {errors["residence-zip"] && (
                <div className="error-msg">
                  {errors["residence-zip"].message}
                </div>
              )}
              <div className=" form-group field-city-limits"></div>
              <div className="block">
                <label
                  for="city-limits"
                  className="text-gray-700 text-gray-700 "
                >
                  Is the residence within city limits?
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                  <br />
                </label>
                <select
                  className="form-select city-limits"
                  {...register("city-limits")}
                  access="false"
                  id="city-limits"
                  required="required"
                  aria-required="true"
                >
                  <option value={undefined} disabled selected="true">
                    Please select:{" "}
                  </option>
                  <option value="yes" id="city-limits-1">
                    Yes
                  </option>
                  <option value="no" id="city-limits-0">
                    No
                  </option>
                </select>
              </div>
              {errors["city-limits"] && (
                <div className="error-msg">{errors["city-limits"].message}</div>
              )}
              <br />
              <h3>Background:</h3>
              <div className=" form-group field-armed-forces"></div>
              <div className="block">
                <label
                  for="armed-forces"
                  className="text-gray-700 text-gray-700 "
                >
                  Was the individual ever in the U.S. Armed Forces?
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                  <br />
                </label>
                <select
                  className="form-select armed-forces"
                  {...register("armed-forces")}
                  access="false"
                  id="armed-forces"
                  required="required"
                  aria-required="true"
                >
                  <option value={undefined} disabled selected="true">
                    Please select:
                  </option>
                  <option value="yes" id="armed-forces-1">
                    Yes
                  </option>
                  <option value="no" id="armed-forces-0">
                    No
                  </option>
                </select>
              </div>
              {errors["armed-forces"] && (
                <div className="error-msg">
                  {errors["armed-forces"].message}
                </div>
              )}
              <div className=" form-group field-armed-forces-discharge-papers"></div>
              <div className="block">
                <label for="armed-forces-discharge-papers" className="">
                  If yes, is a copy of the individual's military discharge
                  papers available?
                  <br />
                </label>
                <select
                  className="form-select armed-forces-discharge-papers"
                  {...register("armed-forces-discharge-papers")}
                  access="false"
                  id="armed-forces-discharge-papers"
                >
                  <option value={undefined} disabled selected="true">
                    If applicable, please select:
                  </option>
                  <option value="yes" id="armed-forces-discharge-papers-1">
                    Yes
                  </option>
                  <option value="no" id="armed-forces-discharge-papers-0">
                    No
                  </option>
                </select>
              </div>
              {errors["armed-forces-discharge-papers"] && (
                <div className="error-msg">
                  {errors["armed-forces-discharge-papers"].message}
                </div>
              )}
              <div className=" form-group field-education"></div>
              <div className="block">
                <label for="education" className="text-gray-700 text-gray-700 ">
                  Education completed:
                  <span className="tooltip">
                    <span className="tooltip-text">
                      (the highest degree or level of school completed by
                      individual)
                    </span>
                    ?
                  </span>
                  <br />
                </label>
                <select
                  className="form-select individual-form"
                  {...register("education")}
                  id="education"
                >
                  <option value={undefined} disabled selected="true">
                    Please select:
                  </option>
                  <option value="8th grade" id="education-0">
                    8th grade or less
                  </option>
                  <option value="12th grade" id="education-1">
                    9th-12th grade; no diploma
                  </option>
                  <option value="High school" id="education-2">
                    High school graduate or GED completed
                  </option>
                  <option value="Some college" id="education-3">
                    Some college credit, but no degree
                  </option>
                  <option value="Associate degree" id="education-4">
                    Associate degree
                  </option>
                  <option value="Bachelor's degree" id="education-5">
                    Bachelor's degree
                  </option>
                  <option value="Master's degree" id="education-6">
                    Master's degree
                  </option>
                  <option value="Doctorate" id="education-7">
                    Doctorate or Professional degree
                  </option>
                </select>
              </div>
              {errors["education"] && (
                <div className="error-msg">{errors["education"].message}</div>
              )}
              <div className=" form-group field-race"></div>
              <div className="block">
                <label for="race" className="text-gray-700 text-gray-700 ">
                  Individual's Race:
                  <span className="tooltip">
                    <span className="tooltip-text">
                      (if multiple, please specify below)
                    </span>
                    ?
                  </span>
                  <br />
                </label>
                <select
                  className="form-select individual-form"
                  {...register("race")}
                  id="race"
                >
                  <option value={undefined} disabled selected="true">
                    Please select:
                  </option>
                  <option value="Black or African American" id="education-0">
                    Black or African American
                  </option>
                  <option value="White" id="education-1">
                    White
                  </option>
                  <option value="Hispanic" id="education-2">
                    Hispanic
                  </option>
                  <option value="Other" id="education-3">
                    Other (please specify)
                  </option>
                </select>
              </div>
              {errors["race"] && (
                <div className="error-msg">{errors["race"].message}</div>
              )}
              <div className=" form-group field-race-specific"></div>
              <div className="block">
                <label
                  for="race-specific"
                  className="text-gray-700 text-gray-700 "
                >
                  Please specify if necessary:
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("race-specific")}
                  access="false"
                  id="race-specific"
                />
              </div>
              {errors["race-specific"] && (
                <div className="error-msg">
                  {errors["race-specific"].message}
                </div>
              )}
              <div className=" form-group field-father-name">
                <br />
                <h3>Individual's father's full legal name:</h3>
              </div>
              <div className="block">
                <label
                  for="father-name-first"
                  className="text-gray-700 text-gray-700 "
                >
                  First:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("father-name-first")}
                  access="false"
                  id="father-name-first"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["father-name-first"] && (
                <div className="error-msg">
                  {errors["father-name-first"].message}
                </div>
              )}
              <div className="block">
                <label
                  for="father-name-middle"
                  className="text-gray-700 text-gray-700 "
                >
                  Middle:
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("father-name-middle")}
                  access="false"
                  id="father-name-middle"
                />
              </div>
              {errors["father-name-middle"] && (
                <div className="error-msg">
                  {errors["father-name-middle"].message}
                </div>
              )}
              <div className="block">
                <label
                  for="father-name-last"
                  className="text-gray-700 text-gray-700 "
                >
                  Last:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("father-name-last")}
                  access="false"
                  id="father-name-last"
                  required="required"
                  aria-required="true"
                />

                {errors["father-name-last"] && (
                  <div className="error-msg">
                    {errors["father-name-last"].message}
                  </div>
                )}
                <div className=" form-group field-mother-name">
                  <br />
                  <h3>Individual's mother's full name:</h3>
                </div>
                <div className="block">
                  <label
                    for="mother-name-first"
                    className="text-gray-700 text-gray-700 "
                  >
                    First:
                    <span className="required-asterisk">
                      <span className="asterisk-text">required</span>*
                    </span>
                  </label>
                </div>
                <div className="block">
                  <input
                    type="text"
                    className="form-input individual-form"
                    {...register("mother-name-first")}
                    access="false"
                    id="mother-name-first"
                    required="required"
                    aria-required="true"
                  />
                </div>
                {errors["mother-name-first"] && (
                  <div className="error-msg">
                    {errors["mother-name-first"].message}
                  </div>
                )}

                <div className="block">
                  <label
                    for="mother-name-middle"
                    className="text-gray-700 text-gray-700 "
                  >
                    Middle:
                  </label>
                </div>
                <div className="block">
                  <input
                    type="text"
                    className="form-input individual-form"
                    {...register("mother-name-middle")}
                    access="false"
                    id="mother-name-middle"
                  />
                </div>
                {errors["mother-name-middle"] && (
                  <div className="error-msg">
                    {errors["mother-name-middle"].message}
                  </div>
                )}

                <div className="block">
                  <label
                    for="mother-name-last"
                    className="text-gray-700 text-gray-700 "
                  >
                    Last (maiden name):
                    <span className="tooltip">
                      <span className="tooltip-text">
                        (if known, put maiden name, if not, put married name)
                      </span>
                      ?
                    </span>
                    <span className="required-asterisk">
                      <span className="asterisk-text">required</span>*
                    </span>
                  </label>
                </div>
                <div className="block">
                  <input
                    type="text"
                    className="form-input individual-form"
                    {...register("mother-name-last")}
                    access="false"
                    id="mother-name-last"
                    required="required"
                    aria-required="true"
                  />
                </div>
                {errors["mother-name-last"] && (
                  <div className="error-msg">
                    {errors["mother-name-last"].message}
                  </div>
                )}
              </div>
              <h3>Email address: </h3>{" "}
              <div className=" form-group field-contact-email"></div>
              <div className="block">
                <label
                  for="contact-email"
                  className="text-gray-700 text-gray-700 "
                >
                  Email address of the person submitting this form:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("contact-email")}
                  access="false"
                  id="contact-email"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["contact-email"] && (
                <div className="error-msg">
                  {errors["contact-email"].message}
                </div>
              )}
              <h3>Point of contact after death: </h3>{" "}
              <div className=" form-group field-contact-name"></div>
              <div className="block">
                <label
                  for="contact-name"
                  className="text-gray-700 text-gray-700 "
                >
                  Name of primary point of contact after the death:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("contact-name")}
                  access="false"
                  id="contact-name"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["contact-name"] && (
                <div className="error-msg">
                  {errors["contact-name"].message}
                </div>
              )}
              <div className=" form-group field-contact-relationship"></div>
              <div className="block">
                <label
                  for="contact-relationship"
                  className="text-gray-700 text-gray-700 "
                >
                  Contact's relationship to individual:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("contact-relationship")}
                  access="false"
                  id="contact-relationship"
                  required="required"
                  aria-required="true"
                />
              </div>
              {errors["contact-relationship"] && (
                <div className="error-msg">
                  {errors["contact-relationship"].message}
                </div>
              )}
              <h4>Contact's address:</h4>
              <div className=" form-group field-contact-address"></div>
              <div className="block">
                <label
                  for="contact-address"
                  className="text-gray-700 text-gray-700 "
                >
                  Street and number:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("contact-address")}
                  access="false"
                  id="contact-address"
                ></input>
              </div>
              {errors["contact-address"] && (
                <div className="error-msg">
                  {errors["contact-address"].message}
                </div>
              )}
              <div className=" form-group field-contact-city"></div>
              <div className="block">
                <label
                  for="contact-city"
                  className="text-gray-700 text-gray-700 "
                >
                  City:
                  <span className="required-asterisk">
                    <span className="asterisk-text">required</span>*
                  </span>
                </label>
              </div>
              <div className="block">
                <input
                  type="text"
                  className="form-input individual-form"
                  {...register("contact-city")}
                  access="false"
                  id="contact-city"
                ></input>
              </div>
              {errors["contact-city"] && (
                <div className="error-msg">
                  {errors["contact-city"].message}
                </div>
              )}
              <div className=" form-group field-contact-state"></div>
              <div className="block">
                <label
                  for="contact-state"
                  className="text-gray-700 text-gray-700 "
                >
                  State:
                  <br />{" "}
                </label>
                <select
                  className="form-select individual-form"
                  {...register("contact-state")}
                  access="false"
                  id="contact-state"
                >
                  <option value={undefined} disabled selected="true">
                    Please select:
                  </option>
                  <option value="Alabama" id="contact-state-0">
                    Alabama
                  </option>
                  <option value="Alaska" id="contact-state-1">
                    Alaska
                  </option>
                  <option value="Arizona" id="contact-state-2">
                    Arizona
                  </option>
                  <option value="Arkansas" id="contact-state-3">
                    Arkansas
                  </option>
                  <option value="California" id="contact-state-4">
                    California
                  </option>
                  <option value="Colorado" id="contact-state-5">
                    Colorado
                  </option>
                  <option value="Connecticut" id="contact-state-6">
                    Connecticut
                  </option>
                  <option value="Delaware" id="contact-state-7">
                    Delaware
                  </option>
                  <option value="Florida" id="contact-state-8">
                    Florida
                  </option>
                  <option value="Georgia" id="contact-state-9">
                    Georgia
                  </option>
                  <option value="Hawaii" id="contact-state-10">
                    Hawaii
                  </option>
                  <option value="Idaho" id="contact-state-11">
                    Idaho
                  </option>
                  <option value="Illinois" id="contact-state-12">
                    Illinois
                  </option>
                  <option value="Indiana" id="contact-state-13">
                    Indiana
                  </option>
                  <option value="Iowa" id="contact-state-14">
                    Iowa
                  </option>
                  <option value="Kansas" id="contact-state-15">
                    Kansas
                  </option>
                  <option value="Kentucky" id="contact-state-16">
                    Kentucky
                  </option>
                  <option value="Louisiana" id="contact-state-17">
                    Louisiana
                  </option>
                  <option value="Maine" id="contact-state-18">
                    Maine
                  </option>
                  <option value="Maryland" id="contact-state-19">
                    Maryland
                  </option>
                  <option value="Massachusetts" id="contact-state-20">
                    Massachusetts
                  </option>
                  <option value="Michigan" id="contact-state-21">
                    Michigan
                  </option>
                  <option value="Minnesota" id="contact-state-22">
                    Minnesota
                  </option>
                  <option value="Mississippi" id="contact-state-23">
                    Mississippi
                  </option>
                  <option value="Missouri" id="contact-state-24">
                    Missouri
                  </option>
                  <option value="Montana" id="contact-state-25">
                    Montana
                  </option>
                  <option value="Nebraska" id="contact-state-26">
                    Nebraska
                  </option>
                  <option value="Nevada" id="contact-state-27">
                    Nevada
                  </option>
                  <option value="New-Hampshire" id="contact-state-28">
                    New Hampshire
                  </option>
                  <option value="New-Jersey" id="contact-state-29">
                    New Jersey
                  </option>
                  <option value="New-Mexico" id="contact-state-30">
                    New Mexico
                  </option>
                  <option value="New-York" id="contact-state-31">
                    New York
                  </option>
                  <option value="North-Carolina" id="contact-state-32">
                    North Carolina
                  </option>
                  <option value="North-Dakota" id="contact-state-33">
                    North Dakota
                  </option>
                  <option value="Ohio" id="contact-state-34">
                    Ohio
                  </option>
                  <option value="Oklahoma" id="contact-state-35">
                    Oklahoma
                  </option>
                  <option value="Oregon" id="contact-state-36">
                    Oregon
                  </option>
                  <option value="Pennsylvania" id="contact-state-37">
                    Pennsylvania
                  </option>
                  <option value="Rhode-Island" id="contact-state-38">
                    Rhode Island
                  </option>
                  <option value="South-Carolina" id="contact-state-39">
                    South Carolina
                  </option>
                  <option value="South-Dakota" id="contact-state-40">
                    South Dakota
                  </option>
                  <option value="Tennessee" id="contact-state-41">
                    Tennessee
                  </option>
                  <option value="Texas" id="contact-state-42">
                    Texas
                  </option>
                  <option value="Utah" id="contact-state-43">
                    Utah
                  </option>
                  <option value="Vermont" id="contact-state-44">
                    Vermont
                  </option>
                  <option value="Virginia" id="contact-state-45">
                    Virginia
                  </option>
                  <option value="Washington" id="contact-state-46">
                    Washington
                  </option>
                  <option value="West-Virginia" id="contact-state-47">
                    West Virginia
                  </option>
                  <option value="Wisconsin" id="contact-state-48">
                    Wisconsin
                  </option>
                  <option value="Wyoming" id="contact-state-49">
                    Wyoming
                  </option>
                  <option value="Washington-DC" id="contact-state-50">
                    Washington, D.C.
                  </option>
                </select>
                {errors["contact-state"] && (
                  <div className="error-msg">
                    {errors["contact-state"].message}
                  </div>
                )}
                <div className=" form-group field-contact-zip"></div>
                <div className="block">
                  <label
                    for="contact-zip"
                    className="text-gray-700 text-gray-700 "
                  >
                    Zip Code:
                    <span className="required-asterisk">
                      <span className="asterisk-text">required</span>*
                    </span>
                  </label>
                </div>
                <div className="block">
                  <input
                    type="text"
                    className="form-input individual-form"
                    {...register("contact-zip")}
                    access="false"
                    id="contact-zip"
                  ></input>
                </div>{" "}
                {errors["contact-zip"] && (
                  <div className="error-msg">
                    {errors["contact-zip"].message}
                  </div>
                )}
                <div className=" form-group field-contact-telephone"></div>
                <div className="block">
                  <label
                    for="contact-telephone"
                    className="text-gray-700 text-gray-700 "
                  >
                    Contact's telephone number:
                    <span className="required-asterisk">
                      <span className="asterisk-text">required</span>*
                    </span>
                  </label>
                </div>
                <div className="block">
                  <input
                    type="text"
                    placeholder="999-999-9999"
                    className="form-input individual-form"
                    {...register("contact-telephone")}
                    access="false"
                    id="contact-telephone"
                    required="required"
                    aria-required="true"
                  />
                </div>
                {errors["contact-telephone"] && (
                  <div className="error-msg">
                    {errors["contact-telephone"].message}
                  </div>
                )}
                <div className=" form-group field-city-limits"></div>
                <h3>Additional questions:</h3>{" "}
                <div className="block">
                  <label
                    for="city-limits"
                    className="text-gray-700 text-gray-700 "
                  >
                    Is the individual currently under hospice care?
                    <span className="required-asterisk">
                      <span className="asterisk-text">required</span>*
                    </span>
                    <br />
                  </label>
                  <select
                    className="form-select hospice"
                    {...register("hospice")}
                    access="false"
                    id="hospice"
                    required="required"
                    aria-required="true"
                  >
                    <option value={undefined} disabled selected="true">
                      Please select:
                    </option>
                    <option value="yes" id="hospice-1">
                      Yes
                    </option>
                    <option value="no" id="hospice-0">
                      No
                    </option>
                  </select>
                </div>
                {errors["hospice"] && (
                  <div className="error-msg">{errors["hospice"].message}</div>
                )}
                <div className=" form-group field-hospice-name"></div>
                <div className="block">
                  <label
                    for="hospice-name"
                    className="text-gray-700 text-gray-700 "
                  >
                    Name of hospice provider:
                    <span className="tooltip">
                      <span className="tooltip-text">(if yes)</span>?
                    </span>
                  </label>
                </div>
                <div className="block">
                  <input
                    type="text"
                    className="form-input individual-form"
                    {...register("hospice-name")}
                    access="false"
                    id="hospice-name"
                    title="(if yes above)"
                  />
                </div>
                {errors["hospice-name"] && (
                  <div className="error-msg">
                    {errors["hospice-name"].message}
                  </div>
                )}
                <div className=" form-group field-pacemaker"></div>
                <div className="block">
                  <label
                    for="pacemaker"
                    className="text-gray-700 text-gray-700 "
                  >
                    Did the individual have a pacemaker?
                    <span className="required-asterisk">
                      <span className="asterisk-text">required</span>*
                    </span>
                    <br />
                  </label>
                  <select
                    className="form-select pacemaker"
                    {...register("pacemaker")}
                    access="false"
                    id="pacemaker"
                    required="required"
                    aria-required="true"
                  >
                    <option value={undefined} disabled selected="true">
                      Please select:
                    </option>
                    <option value="yes" id="pacemaker-1">
                      Yes
                    </option>
                    <option value="no" id="pacemaker-0">
                      No
                    </option>
                  </select>
                </div>
                {errors["pacemaker"] && (
                  <div className="error-msg">{errors["pacemaker"].message}</div>
                )}
                <div>
                  Three (3) Certified Death Certificates are already included.
                  Please note any additional certificates needed here.
                </div>
                <div className=" form-group field-death-certificates-number"></div>
                <div className="block">
                  <label for="death-certificates-number" className="">
                    Death certificates requested:
                    <span className="tooltip">
                      <span className="tooltip-text">
                        (each additional certificate after the first three costs
                        $15)
                      </span>
                      ?
                    </span>
                    <span className="required-asterisk">
                      <span className="asterisk-text">required</span>*
                    </span>
                    <br />
                  </label>
                </div>
                <div className="block">
                  <small>
                    <b>
                      <span className="mt-2">3 certificates included +</span>
                    </b>
                  </small>
                  <br />
                  <span>
                    <input
                      type="number"
                      className="form-input decedent-form"
                      {...register("death-certificates-number")}
                      access="false"
                      // value="0"
                      min="0"
                      id="death-certificates-number"
                      title="(each additional certificate costs $15)"
                      required="required"
                      aria-required="true"
                    />{" "}
                    additional certificates
                  </span>
                  <br />
                  <span className="mt-2">
                    = <b>{3 + +deathCertificatesNumber} total</b> death
                    certificates.
                  </span>
                </div>
                {errors["pacemaker"] && (
                  <div className="error-msg">{errors["pacemaker"].message}</div>
                )}
              </div>
            </div>
            <div>
              <br />
              <br />
              <p>
                We are committed to making this process as easy as possible for
                you. Please do not hesitate to call us with any questions and/or
                concerns. We are available to you 24 hours a day / 7 days a
                week.
              </p>
            </div>
          </div>
          <div className="flex items-center align-center justify-center py-4">
            <input
              className="py-2 px-4 bg-green-700 hover:bg-green-600 text-base text-white font-bold uppercase rounded shadow-md hover:-translate-1 cursor-pointer"
              type="submit"
              value="Submit"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default PrePlanForm;
